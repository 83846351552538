/* cyrillic-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/SpaceGrotesk-Light.ttf');

}
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/SpaceGrotesk-Regular.ttf');
}
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/SpaceGrotesk-Medium.ttf');
}
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/SpaceGrotesk-Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/Inter-Light.ttf');

}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Inter-Regular.ttf');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Inter-Medium.ttf');
}
//@font-face {
//  font-family: 'Inter';
//  font-style: normal;
//  font-weight: 700;
//  src: url('../../assets/fonts/Inter-Bold.ttf');
//}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Poppins-Medium.ttf');
}


@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Poppins-Bold.ttf');
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  //line-height: 1.7;
  color: white;
}

* {
  font-size: $default-font-size;
  font-family: 'Inter', sans-serif;
}

body * {
  // color: white;
}
