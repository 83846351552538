@import "../../../style/abstracts/mixins";
@import "../../../style/abstracts/variables";

.header {
  display: flex;
  height: 80px;
  //padding: 20px 15rem 20px 15rem;
  width: 1400px;
  max-width: calc(100vw - 6rem);
  margin: 0 auto;
  align-items: center;
  z-index: 2000;
  top: 0;
  left: 0;
  justify-content: space-between;
  position: relative;

  @include respond(phone) {
    position: relative;
    background: black;
    padding: 2rem 1.5rem 1rem 2rem;
    max-width: 100%;
    height: 60px;
  }

  a {
    text-decoration: none;
  }

  .logo-box {
    text-align: right;
    cursor: pointer;
    &.play::after {
      content: 'PLAY';
      color: $color-pink;
    }

    &.tooltip .tooltiptext {
      top: 30%;
      left: 110%;
    }
  }

  .logo-box::after {
    content: 'BETA';
    font-size: 12px;
    vertical-align: top;
    color: $color-orange-1;
    border: 1px solid;
    border-radius: 5px;
    padding: 3px 4px 2px 4px;
    margin-left: 2px;

    @include respond(phone) {
      position: absolute;
      font-size: 8px;
      border-radius: 4px;
      transform: translate(-2rem, -2rem);
      z-index: 10;
      margin-left: 10px;
    }
    @include respond(small-phone){
      transform: translate(-1rem, -0.5rem);
    }
  }

  .menu {
    display: flex;
    justify-content: center;
    background: #1B1D21;
    border-radius: 16px;
    padding: 0.75rem;

    &--item {
      color: white;
      text-decoration: none;
      font-weight: 500;
      font-size: 19px;
      display: block;
      text-align: center;
      padding: 0.75rem 1.5rem;
      border-radius: 16px;
      transition: all 0.25s ease-out;

      &:not(:last-child) {
        margin-right: 1rem;
      }
     
      &.active {
        background: $color-primary;
      }
    }
  }

  .tooltip {
    cursor: pointer;

    &:hover .tooltiptext {
      visibility: visible;
    }

    .tooltiptext {
      visibility: hidden;
      background-color: white;
      color: black;
      text-align: center;
      border-radius: 6px;
      white-space: nowrap;
      font-weight: 400;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 50%;
      margin-top: 10px;
      margin-left: -60px;
      padding: 0.5rem;
    }
  }

  &__right {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 2rem;
    }


    &--account-btn {
      border: 1px solid $color-primary;
      color: $color-primary;
      border-radius: 1rem;
      padding: 1rem;
      cursor: pointer;
    }

    &--connect-wallet {
      border: 1px solid $color-primary;
      color: $color-primary;
      border-radius: 1rem;
      padding: 1rem;
      cursor: pointer;
    }

    &--bnt-setting {
      background: $color-card;
      padding: 1rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .network, .dapp-menu {
    &-select {
      position: relative;
    }

    &-button {
      background: $color-card;
      padding: 1rem 2rem;
      border-radius: 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      & > *:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &-items {
      position: absolute;
      left: 0rem;
      top: 5.5rem;
      width: fit-content;
      white-space: nowrap;
      transform-origin: top right;
      border-radius: 0.4rem;
      background: $color-modal-bg;
      border: 1px solid $color-grey-dark;
      box-shadow: 2px 4px 8px var(--onedex-color-card-shadow-bg);
      list-style: none;
      cursor: pointer;
      outline: none;
      z-index: 1000;
      @include respond(phone) {
        min-width: 12.5rem;
        right: -3.5rem;
      }

    }

    &-item {
      display: flex !important;
      align-items: center;
      font-size: 1.5rem;
      color: #a0a3c4;
      padding-bottom:z 1.5rem;
      font-size: 1.4rem;
      padding: 0.82rem 0.8rem;
      border-radius: 0.4rem;
      cursor: pointer;
      & > *:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &-item:hover {
      background: $color-button-bg-hover !important;
      border-radius: 0.4rem;
      opacity: 1;
      color: #eee;
    }

    &-item > p {
      margin: 0px;
      padding-left: 1rem;
    }

    &-item > a {
      display: inline-flex;
    }
  }

  .dapp-menu {
    &-select {
      @include respond(phone) {
        margin-right: 0;
      }
    }
    &-item {
      &.active {
        background: $color-grey-dark;
      }
    }
    
    &-button {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-items {
      left: -7.5rem;
      @include respond(phone) {
        top: 6.5rem;
        left: -12.5rem;
      }
    }

    &-item {
      text-decoration: none;
      //color: white;
      font-size: 1.5rem;
    }
  }
}

.navigation {
  width: 60px;
  height: 80px;
  position: relative;

  &__checkbox {
    display: none;
  }

  &__button {
    height: 60px;
    width: 60px;
    position: absolute;
    border-radius: 50%;
    z-index: 2000;
    box-shadow: 0 1rem 3rem rgba($color-black, .1);
    text-align: center;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: fixed;
    top: 20px;
    right: 20px;
    background-image: radial-gradient(black, #05264E);
    z-index: 1000;
    transform: scale(0);
    transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;

    opacity: 0;
    width: 0;
    transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    pointer-events: none;
  }

  &__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    width: 100%;
  }

  &__item {
    margin: 1rem;
  }

  &__link {
    line-height: 1.5;

    display: inline-block;
    font-size: 3rem;
    font-weight: 400;
    padding: 1rem 2rem;
    color: $color-white;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .25s;

    span {
      margin-right: 1.5rem;
      display: inline-block;
    }
  }


  //FUNCTIONALITY
  &__checkbox:checked ~ &__background {
    opacity: 1;
    transform: scale(80);
  }

  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100%;
    pointer-events: auto;
  }


  //ICON
  &__icon {
    position: relative;
    margin-top: 50%;

    &,
    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: white;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s;
    }

    &::before {
      top: -.8rem;
    }

    &::after {
      top: .8rem;
    }
  }

  &__button:hover &__icon::before {
    top: -1rem;
  }

  &__button:hover &__icon::after {
    top: 1rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }
}

.link-connect-to-network {
  color: #60a0ea;
  text-decoration: underline;
}

.logo-image {
  width: 110px;
}
.logo-hero-image {
  width: 50px;
}
.logo-horizon-image {
  height: 52px;
}


