
.MuiPaper-root {
  color: #D0DCE8;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  backdrop-filter: blur(33px);
}

.MuiPaper-root.ohm-card {
  width: 97%;
  padding: 20px 30px 30px 30px;
  overflow: hidden;
  max-width: 833px;
  border-radius: 10px;
  margin-bottom: 1.8rem;
}

.MuiPaper-root.ohm-modal {
  background-color: #24242699;
}

.MuiPaper-root.ohm-menu {
  margin: 0px;
  padding: 22px 0px;
  border-radius: 10px;
  backdrop-filter: blur(33px);
  background-color: rgba(36, 40, 49, 1);
}

.MuiPaper-root.ohm-popover {
  color: #D0DCE8;
  backdrop-filter: blur(15px);
  background-color: rgba(54, 56, 64, 0.99);
}

.MuiPaper-root.ohm-chart-card {
  width: 97%;
  padding: 20px 0px;
  max-width: 700px;
  white-space: nowrap;
  margin-bottom: 1.8rem;
}

.MuiPaper-rounded {
  border-radius: 4px;
}

.MuiPaper-outlined {
  border: 1px solid rgba(255, 255, 255, 0.12);
}

.MuiPaper-elevation0 {
  box-shadow: none;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}


                                      .MuiGrid-root.grid-button {
                                        border-color: #FFFFFF !important;
                                      }
.MuiGrid-root.grid-button:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.MuiGrid-root.grid-button.current {
  border-color: #28E0B2 !important;
  background-color: #00000038;
}
.MuiGrid-root.grid-button.current:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.MuiGrid-item {
  margin: 0;
  box-sizing: border-box;
}
.MuiGrid-zeroMinWidth {
  min-width: 0;
}
.MuiGrid-direction-xs-column {
  flex-direction: column;
}
.MuiGrid-direction-xs-column-reverse {
  flex-direction: column-reverse;
}
.MuiGrid-direction-xs-row-reverse {
  flex-direction: row-reverse;
}
.MuiGrid-wrap-xs-nowrap {
  flex-wrap: nowrap;
}
.MuiGrid-wrap-xs-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.MuiGrid-align-items-xs-center {
  align-items: center;
}
.MuiGrid-align-items-xs-flex-start {
  align-items: flex-start;
}
.MuiGrid-align-items-xs-flex-end {
  align-items: flex-end;
}
.MuiGrid-align-items-xs-baseline {
  align-items: baseline;
}
.MuiGrid-align-content-xs-center {
  align-content: center;
}
.MuiGrid-align-content-xs-flex-start {
  align-content: flex-start;
}
.MuiGrid-align-content-xs-flex-end {
  align-content: flex-end;
}
.MuiGrid-align-content-xs-space-between {
  align-content: space-between;
}
.MuiGrid-align-content-xs-space-around {
  align-content: space-around;
}
.MuiGrid-justify-content-xs-center {
  justify-content: center;
}
.MuiGrid-justify-content-xs-flex-end {
  justify-content: flex-end;
}
.MuiGrid-justify-content-xs-space-between {
  justify-content: space-between;
}
.MuiGrid-justify-content-xs-space-around {
  justify-content: space-around;
}
.MuiGrid-justify-content-xs-space-evenly {
  justify-content: space-evenly;
}
.MuiGrid-spacing-xs-1 {
  width: calc(100% + 8px);
  margin: -4px;
}
.MuiGrid-spacing-xs-1 > .MuiGrid-item {
  padding: 4px;
}
.MuiGrid-spacing-xs-2 {
  width: calc(100% + 16px);
  margin: -8px;
}
.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 8px;
}
.MuiGrid-spacing-xs-3 {
  width: calc(100% + 24px);
  margin: -12px;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 12px;
}
.MuiGrid-spacing-xs-4 {
  width: calc(100% + 32px);
  margin: -16px;
}
.MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 16px;
}
.MuiGrid-spacing-xs-5 {
  width: calc(100% + 40px);
  margin: -20px;
}
.MuiGrid-spacing-xs-5 > .MuiGrid-item {
  padding: 20px;
}
.MuiGrid-spacing-xs-6 {
  width: calc(100% + 48px);
  margin: -24px;
}
.MuiGrid-spacing-xs-6 > .MuiGrid-item {
  padding: 24px;
}
.MuiGrid-spacing-xs-7 {
  width: calc(100% + 56px);
  margin: -28px;
}
.MuiGrid-spacing-xs-7 > .MuiGrid-item {
  padding: 28px;
}
.MuiGrid-spacing-xs-8 {
  width: calc(100% + 64px);
  margin: -32px;
}
.MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 32px;
}
.MuiGrid-spacing-xs-9 {
  width: calc(100% + 72px);
  margin: -36px;
}
.MuiGrid-spacing-xs-9 > .MuiGrid-item {
  padding: 36px;
}
.MuiGrid-spacing-xs-10 {
  width: calc(100% + 80px);
  margin: -40px;
}
.MuiGrid-spacing-xs-10 > .MuiGrid-item {
  padding: 40px;
}
.MuiGrid-grid-xs-auto {
  flex-grow: 0;
  max-width: none;
  flex-basis: auto;
}
.MuiGrid-grid-xs-true {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
}
.MuiGrid-grid-xs-1 {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%;
}
.MuiGrid-grid-xs-2 {
  flex-grow: 0;
  max-width: 16.666667%;
  flex-basis: 16.666667%;
}
.MuiGrid-grid-xs-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
}
.MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
}
.MuiGrid-grid-xs-5 {
  flex-grow: 0;
  max-width: 41.666667%;
  flex-basis: 41.666667%;
}
.MuiGrid-grid-xs-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
}
.MuiGrid-grid-xs-7 {
  flex-grow: 0;
  max-width: 58.333333%;
  flex-basis: 58.333333%;
}
.MuiGrid-grid-xs-8 {
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
}
.MuiGrid-grid-xs-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
}
.MuiGrid-grid-xs-10 {
  flex-grow: 0;
  max-width: 83.333333%;
  flex-basis: 83.333333%;
}
.MuiGrid-grid-xs-11 {
  flex-grow: 0;
  max-width: 91.666667%;
  flex-basis: 91.666667%;
}
.MuiGrid-grid-xs-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
}
@media (min-width:600px) {
  .MuiGrid-grid-sm-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }
  .MuiGrid-grid-sm-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-sm-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-sm-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-sm-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-sm-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-sm-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-sm-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-sm-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-sm-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-sm-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-sm-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-sm-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-sm-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width:960px) {
  .MuiGrid-grid-md-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }
  .MuiGrid-grid-md-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-md-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-md-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-md-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-md-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-md-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-md-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-md-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-md-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-md-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width:1280px) {
  .MuiGrid-grid-lg-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }
  .MuiGrid-grid-lg-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-lg-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-lg-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-lg-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-lg-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-lg-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-lg-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-lg-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-lg-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-lg-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-lg-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-lg-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-lg-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width:1920px) {
  .MuiGrid-grid-xl-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }
  .MuiGrid-grid-xl-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-xl-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-xl-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-xl-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-xl-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-xl-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-xl-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-xl-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-xl-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-xl-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-xl-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-xl-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-xl-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
                                            .MuiTypography-root {
                                              margin: 0;
                                            }
.MuiTypography-root.grid-message-typography {
  color: #D0DCE8B2;
}
.MuiTypography-root.chain-highlight {
  color: #DADADA;
}
.MuiTypography-root.current {
  color: #28E0B2;
}
.MuiTypography-body2 {
  font-size: 0.75rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1;
}
.MuiTypography-body1 {
  font-size: 0.875rem;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1;
}
.MuiTypography-caption {
  font-size: 0.75rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.66;
}
.MuiTypography-button {
  font-size: 1.125rem;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.75;
  text-transform: none;
}
@media (min-width:600px) {
  .MuiTypography-button {
    font-size: 1.1429rem;
  }
}
@media (min-width:960px) {
  .MuiTypography-button {
    font-size: 1.2857rem;
  }
}
@media (min-width:1280px) {
  .MuiTypography-button {
    font-size: 1.2857rem;
  }
}
.MuiTypography-h1 {
  font-size: 2.15rem;
  font-family: Montserrat;
  font-weight: 300;
  line-height: 1.167;
}
@media (min-width:600px) {
  .MuiTypography-h1 {
    font-size: 2.7849rem;
  }
}
@media (min-width:960px) {
  .MuiTypography-h1 {
    font-size: 2.9991rem;
  }
}
@media (min-width:1280px) {
  .MuiTypography-h1 {
    font-size: 3.2134rem;
  }
}
.MuiTypography-h2 {
  font-size: 1.65rem;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 1.3px;
}
@media (min-width:600px) {
  .MuiTypography-h2 {
    font-size: 1.875rem;
  }
}
@media (min-width:960px) {
  .MuiTypography-h2 {
    font-size: 2.0833rem;
  }
}
@media (min-width:1280px) {
  .MuiTypography-h2 {
    font-size: 2.2917rem;
  }
}
.MuiTypography-h3 {
  font-size: 1.375rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.167;
}
@media (min-width:600px) {
  .MuiTypography-h3 {
    font-size: 1.4996rem;
  }
}
@media (min-width:960px) {
  .MuiTypography-h3 {
    font-size: 1.7138rem;
  }
}
@media (min-width:1280px) {
  .MuiTypography-h3 {
    font-size: 1.7138rem;
  }
}
.MuiTypography-h4 {
  font-size: 1.25rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.235;
}
@media (min-width:600px) {
  .MuiTypography-h4 {
    font-size: 1.417rem;
  }
}
@media (min-width:960px) {
  .MuiTypography-h4 {
    font-size: 1.417rem;
  }
}
@media (min-width:1280px) {
  .MuiTypography-h4 {
    font-size: 1.417rem;
  }
}
.MuiTypography-h5 {
  font-size: 1.125rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0.4px;
}
@media (min-width:600px) {
  .MuiTypography-h5 {
    font-size: 1.1244rem;
  }
}
@media (min-width:960px) {
  .MuiTypography-h5 {
    font-size: 1.3118rem;
  }
}
@media (min-width:1280px) {
  .MuiTypography-h5 {
    font-size: 1.3118rem;
  }
}
.MuiTypography-h6 {
  font-size: 1rem;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.6;
}
.MuiTypography-subtitle1 {
  font-size: 1rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.75;
}
.MuiTypography-subtitle2 {
  font-size: 0.875rem;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.57;
}
.MuiTypography-overline {
  font-size: 0.75rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 2.66;
  text-transform: uppercase;
}
.MuiTypography-srOnly {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
}
.MuiTypography-alignLeft {
  text-align: left;
}
.MuiTypography-alignCenter {
  text-align: center;
}
.MuiTypography-alignRight {
  text-align: right;
}
.MuiTypography-alignJustify {
  text-align: justify;
}
.MuiTypography-noWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.MuiTypography-gutterBottom {
  margin-bottom: 0.35em;
}
.MuiTypography-paragraph {
  margin-bottom: 16px;
}
.MuiTypography-colorInherit {
  color: inherit;
}
.MuiTypography-colorPrimary {
  color: #D0DCE8;
}
.MuiTypography-colorSecondary {
  color: #f50057;
}
.MuiTypography-colorTextPrimary {
  color: #D0DCE8;
}
.MuiTypography-colorTextSecondary {
  color: #D0DCE8B2;
}
.MuiTypography-colorError {
  color: #f44336;
}
.MuiTypography-displayInline {
  display: inline;
}
.MuiTypography-displayBlock {
  display: block;
}
                                        .MuiDrawer-root {
                                          width: 400px;
                                          flex-shrink: 0;
                                        }
.MuiDrawer-docked {
  flex: 0 0 auto;
}
.MuiDrawer-paper {
  top: 0;
  flex: 1 0 auto;
  width: inherit;
  height: 100%;
  display: flex;
  outline: 0;
  padding: 0;
  z-index: 7;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  background-color: inherit;
  -webkit-overflow-scrolling: touch;
}
.MuiDrawer-paperAnchorLeft {
  left: 0;
  right: auto;
}
.MuiDrawer-paperAnchorRight {
  left: auto;
  right: 0;
}
.MuiDrawer-paperAnchorTop {
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  height: auto;
  max-height: 100%;
}
.MuiDrawer-paperAnchorBottom {
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  max-height: 100%;
}
.MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.MuiDrawer-paperAnchorDockedTop {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.MuiDrawer-paperAnchorDockedRight {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.MuiDrawer-paperAnchorDockedBottom {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
                                      .MuiLink-root {
                                        color: #D0DCE8;
                                        width: 100%;
                                        cursor: pointer;
                                        padding: 0.5rem 0.5rem 0.5rem 1.2rem;
                                        text-underline-offset: .23rem;
                                      }
.MuiLink-root:hover {
  color: #28E0B2;
  underline: none;
  text-decoration: none;
}
.MuiLink-root.active {
  color: #26E2B3;
  border: 1px solid #13cfcf11;
  background: #13182357;
  box-shadow: 12px 12px 22px rgba(0, 0, 0, 0.25);
  border-radius: 63px;
  backdrop-filter: blur(15px);
}
.MuiLink-root:hover.active {
  color: #D0DCE8;
}
.MuiLink-underlineNone {
  text-decoration: none;
}
.MuiLink-underlineHover {
  text-decoration: none;
}
.MuiLink-underlineHover:hover {
  text-decoration: underline;
}
.MuiLink-underlineAlways {
  text-decoration: underline;
}
.MuiLink-button {
  border: 0;
  cursor: pointer;
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.MuiLink-button::-moz-focus-inner {
  border-style: none;
}
.MuiLink-button.Mui-focusVisible {
  outline: auto;
}
                                        .MuiAppBar-root {
                                          width: 100%;
                                          display: flex;
                                          z-index: 1100;
                                          box-sizing: border-box;
                                          flex-shrink: 0;
                                          flex-direction: column;
                                        }
.MuiAppBar-positionFixed {
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
}
@media print {
  .MuiAppBar-positionFixed {
    position: absolute;
  }
}
.MuiAppBar-positionAbsolute {
  top: 0;
  left: auto;
  right: 0;
  position: absolute;
}
.MuiAppBar-positionSticky {
  top: 0;
  left: auto;
  right: 0;
  position: sticky;
}
.MuiAppBar-positionStatic {
  position: static;
}
.MuiAppBar-positionRelative {
  position: relative;
}
.MuiAppBar-colorDefault {
  color: #fff;
  background-color: #212121;
}
.MuiAppBar-colorPrimary {
  color: rgba(0, 0, 0, 0.87);
  background-color: #D0DCE8;
}
.MuiAppBar-colorSecondary {
  color: #fff;
  background-color: #f50057;
}
.MuiAppBar-colorInherit {
  color: inherit;
}
.MuiAppBar-colorTransparent {
  color: inherit;
  background-color: transparent;
}
                                         .MuiToolbar-root {
                                           display: flex;
                                           position: relative;
                                           align-items: center;
                                           justify-content: flex-end;
                                         }
.MuiToolbar-gutters {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width:600px) {
  .MuiToolbar-gutters {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.MuiToolbar-regular {
  min-height: 56px;
}
@media (min-width:0px) and (orientation: landscape) {
  .MuiToolbar-regular {
    min-height: 48px;
  }
}
@media (min-width:600px) {
  .MuiToolbar-regular {
    min-height: 64px;
  }
}
.MuiToolbar-dense {
  min-height: 48px;
}
                                            .MuiButtonBase-root {
                                              color: inherit;
                                              border: 0;
                                              cursor: pointer;
                                              margin: 0;
                                              display: inline-flex;
                                              outline: 0;
                                              padding: 0;
                                              position: relative;
                                              align-items: center;
                                              user-select: none;
                                              border-radius: 0;
                                              vertical-align: middle;
                                              -moz-appearance: none;
                                              justify-content: center;
                                              text-decoration: none;
                                              background-color: transparent;
                                              -webkit-appearance: none;
                                              -webkit-tap-highlight-color: transparent;
                                            }
.MuiButtonBase-root::-moz-focus-inner {
  border-style: none;
}
.MuiButtonBase-root.Mui-disabled {
  cursor: default;
  pointer-events: none;
}
@media print {
  .MuiButtonBase-root {
    -webkit-print-color-adjust: exact;
  }
}
                                        .MuiButton-root {
                                          color: #D0DCE8;
                                          padding: 6px 16px;
                                          font-size: 1.125rem;
                                          min-width: max-content;
                                          box-sizing: border-box;
                                          max-height: 40px;
                                          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                          font-family: Montserrat;
                                          font-weight: 500;
                                          line-height: 1.75;
                                          white-space: nowrap;
                                          border-radius: 5px;
                                          text-transform: none;
                                          text-decoration: none;
                                        }
@media (min-width:600px) {
  .MuiButton-root {
    font-size: 1.1429rem;
  }
}
@media (min-width:960px) {
  .MuiButton-root {
    font-size: 1.2857rem;
  }
}
@media (min-width:1280px) {
  .MuiButton-root {
    font-size: 1.2857rem;
  }
}
.MuiButton-root:hover {
  text-decoration: none;
  background-color: rgba(208, 220, 232, 0.08);
}
.MuiButton-root.Mui-disabled {
  color: rgba(255, 255, 255, 0.3);
}
@media (hover: none) {
  .MuiButton-root:hover {
    background-color: transparent;
  }
}
.MuiButton-root:hover.Mui-disabled {
  background-color: transparent;
}
.MuiButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}
.MuiButton-text {
  padding: 6px 8px;
}
.MuiButton-text:hover {
  background-color: #00000000;
}
.MuiButton-textPrimary {
  color: #D0DCE8B2;
}
.MuiButton-textPrimary:hover {
  color: #28E0B2;
  background-color: #00000000;
}
.MuiButton-textPrimary:active {
  color: #28E0B2;
  border-bottom: #28E0B2;
}
@media (hover: none) {
  .MuiButton-textPrimary:hover {
    background-color: transparent;
  }
}
.MuiButton-textSecondary {
  color: #D0DCE8;
  padding: 2px 2px;
  text-transform: none;
  text-decoration: none;
}
.MuiButton-textSecondary:hover {
  color: #28E0B2;
  background-color: #00000000;
}
@media (hover: none) {
  .MuiButton-textSecondary:hover {
    background-color: transparent;
  }
}
.MuiButton-outlined {
  border: 1px solid rgba(255, 255, 255, 0.23);
  padding: 5px 15px;
}
.MuiButton-outlined.Mui-disabled {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.MuiButton-outlinedPrimary {
  color: #28E0B2;
  border: 1px solid rgba(208, 220, 232, 0.5);
  height: 33px;
  padding: 9px 20px;
  border-color: #28E0B2;
}
.MuiButton-outlinedPrimary:hover {
  color: #333333;
  border: 1px solid #D0DCE8;
  background-color: #00FFBF;
}
@media (hover:none) {
  .MuiButton-outlinedPrimary {
    color: #28E0B2;
    border-color: #28E0B2;
  }
  .MuiButton-outlinedPrimary:hover {
    color: #333333;
    text-decoration: none !important;
    background-color: #00FFBF !important;
  }
}
@media (hover: none) {
  .MuiButton-outlinedPrimary:hover {
    background-color: transparent;
  }
}
.MuiButton-outlinedSecondary {
  color: #D0DCE8;
  border: 1px solid rgba(245, 0, 87, 0.5);
  height: 33px;
  padding: 9px 20px;
  font-size: 1.1em;
  border-color: #D0DCE8;
  text-transform: none;
  text-decoration: none;
}
.MuiButton-outlinedSecondary:hover {
  color: #28E0B2;
  border: 1px solid #f50057;
  border-color: #28E0B2;
  background-color: transparent;
}
.MuiButton-outlinedSecondary.Mui-disabled {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
@media (hover: none) {
  .MuiButton-outlinedSecondary:hover {
    background-color: transparent;
  }
}
.MuiButton-contained {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  background-color: #e0e0e0;
}
.MuiButton-contained:hover {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  background-color: #d5d5d5;
}
.MuiButton-contained.Mui-focusVisible {
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}
.MuiButton-contained:active {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
}
.MuiButton-contained.Mui-disabled {
  color: rgba(255, 255, 255, 0.3);
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.12);
}
@media (hover: none) {
  .MuiButton-contained:hover {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #e0e0e0;
  }
}
.MuiButton-contained:hover.Mui-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.MuiButton-containedPrimary {
  color: #333333;
  border: 0;
  font-weight: 500;
  background-color: #28E0B2;
}
.MuiButton-containedPrimary:hover {
  background-color: #00FFBF;
}
.MuiButton-containedPrimary:active {
  background-color: #00FFBF;
}
@media (hover:none) {
  .MuiButton-containedPrimary {
    color: #333333;
    background-color: #28E0B2;
  }
  .MuiButton-containedPrimary:hover {
    background-color: #00FFBF;
  }
}
@media (hover: none) {
  .MuiButton-containedPrimary:hover {
    background-color: #D0DCE8;
  }
}
.MuiButton-containedSecondary {
  color: #D0DCE8;
  font-weight: 400;
}
.MuiButton-containedSecondary:hover {
  background-color: rgba(255, 255, 255, 0.15) !important;
}
.MuiButton-containedSecondary:active {
  background-color: rgba(255, 255, 255, 0.15);
}
.MuiButton-containedSecondary:focus {
}
@media (hover:none) {
  .MuiButton-containedSecondary {
    color: #D0DCE8;
  }
  .MuiButton-containedSecondary:hover {
    background-color: rgba(255, 255, 255, 0.15) !important;
  }
}
@media (hover: none) {
  .MuiButton-containedSecondary:hover {
    background-color: #f50057;
  }
}
.MuiButton-disableElevation {
  box-shadow: none;
}
.MuiButton-disableElevation:hover {
  box-shadow: none;
}
.MuiButton-disableElevation.Mui-focusVisible {
  box-shadow: none;
}
.MuiButton-disableElevation:active {
  box-shadow: none;
}
.MuiButton-disableElevation.Mui-disabled {
  box-shadow: none;
}
.MuiButton-colorInherit {
  color: inherit;
  border-color: currentColor;
}
.MuiButton-textSizeSmall {
  padding: 4px 5px;
  font-size: 0.8125rem;
}
.MuiButton-textSizeLarge {
  padding: 8px 11px;
  font-size: 0.9375rem;
}
.MuiButton-outlinedSizeSmall {
  padding: 3px 9px;
  font-size: 0.8125rem;
}
.MuiButton-outlinedSizeLarge {
  padding: 7px 21px;
  font-size: 0.9375rem;
}
.MuiButton-containedSizeSmall {
  padding: 4px 10px;
  font-size: 0.8125rem;
}
.MuiButton-containedSizeLarge {
  padding: 8px 22px;
  font-size: 0.9375rem;
}
.MuiButton-fullWidth {
  width: 100%;
}
.MuiButton-startIcon {
  display: inherit;
  margin-left: -4px;
  margin-right: 8px;
}
.MuiButton-startIcon.MuiButton-iconSizeSmall {
  margin-left: -2px;
}
.MuiButton-endIcon {
  display: inherit;
  margin-left: 8px;
  margin-right: -4px;
}
.MuiButton-endIcon.MuiButton-iconSizeSmall {
  margin-right: -2px;
}
.MuiButton-iconSizeSmall > *:first-child {
  font-size: 18px;
}
.MuiButton-iconSizeMedium > *:first-child {
  font-size: 20px;
}
.MuiButton-iconSizeLarge > *:first-child {
  font-size: 22px;
}
                                         .jss6 {
                                           top: 0;
                                           z-index: 10;
                                           position: absolute;
                                           background: transparent;
                                           align-items: flex-end;
                                           backdrop-filter: none;
                                           justify-content: flex-end;
                                         }
@media (min-width:600px) {
  .jss6 {
    width: 100%;
    padding: 10px;
  }
}
.jss7 {
  margin-right: 16px;
  background-color: transparent;
}
@media (min-width:981px) {
  .jss7 {
    display: none;
  }
}
@media (min-width:960px) {
  .jss1 {
    width: 280px;
    flex-shrink: 0;
  }
}
.jss2 {
  height: 100%;
  padding: 8px;
  overflow: auto;
  flex-grow: 1;
  transition: margin 969ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  margin-left: 280px;
}
.jss3 {
  transition: margin 969ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  margin-left: 0;
}
.jss4 {
  min-height: 56px;
}
@media (min-width:0px) and (orientation: landscape) {
  .jss4 {
    min-height: 48px;
  }
}
@media (min-width:600px) {
  .jss4 {
    min-height: 64px;
  }
}
.jss5 {
  width: 280px;
}
