@import "../../style/abstracts/variables";

.tou-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: all .25s ease-out;
  visibility: visible;
  outline: none;
  text-decoration: none;
  font-size: 70%;
  color: #000;
  opacity: 1;

  & *::selection {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(30, 30, 30, 0.55);
  }

  .modal {
    border-radius: 20px;
    width: 90vw;
    max-width: 52rem;
    z-index: 1001;
    padding: 1rem 2rem 2rem 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #2a2c32;
    color: white;

    .head {
      h1 {
        font-size: 2.2rem;
        padding: 1.5rem 0;
      }
    }
    .uot-content {
      width: 100%;
      height: 100%;
    }
    .content {
      padding: 1rem;
      border-radius: 1rem;
      max-height: 400px;
      overflow: scroll;
      white-space: pre-line;

      .dark & {
        background: #181818;
      }

      & > p {
        margin: 1rem 0;
        font-size: 1.4rem;
      }
      & > h2 {
        font-size: 1.8rem;
        margin: 2rem 0;
        font-weight: 700;
      }
      & > h3 {
        font-size: 1.6rem;
        margin: 1.5rem 0;
      }
      & > ul {
        padding-left: 2rem;
        li {
          font-size: 1.4rem;
        }
      }


      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #a8a8a8;
        border-radius: 1rem;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #989898;
      }
    }

    .action {
      margin-top: 2rem;
      text-align: center;
      #accept-tou {
        //display: none;
      }

      .accept-tou-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        & > *:not(:last-child) {
          margin-right: 1rem;
        }
      }

      .checkbox {
        display: flex;
        cursor: pointer;
      }

      .accept-tou-label {
        text-align: center;
        cursor: pointer;
        font-size: 1.4rem;
        //background: linear-gradient(270deg, $color-primary-light-1 10.53%, $color-primary-light 100%);
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
      }

      .continue-btn {
        padding: 12px 16px;
        font-weight: 700;
        font-size: 14px;
        color: #FFFFFF;
        border-radius: 16px;
        border: none;
        cursor: pointer;
        transition: all 0.25s ease-out;
        background: #01A7FA;

        &:disabled {
          background: rgba(128, 128, 128, 0.4);
          cursor: not-allowed;
        }
      }
    }
  }
}
