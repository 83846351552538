// COLORS
$color-orange: #FDBD3A;
$color-orange-1: #FFBE1B;
$color-orange-dark: #CE7C00;
$color-orange-dark-1: #AD6319;
$color-green: #5DB903;
$color-pink: #FF98E5;
$color-grey-light:  #9CA3AFA3;
$color-grey-dark: #494949;
$color-grey: #808191;
$color-danger:  #C23C3C;
$color-brown-light:  #E9D1B5;
$color-brown-light-1:  #ECE6CA;

$color-black: #232323;
$color-white: #ffffff;
$color-text-secondary: #A1A1AA;

$color-primary: #01A7FA;
$color-secondary: #01A7FA;
$color-secondary-dark: #2F80ED;
$color-light-blue: #8AC9F9;
$color-yellow: #F7D479;
$color-text-normal: #D0DCE8;
$color-overlay: rgba(0, 0, 0, 0.5);
$color-modal-bg: rgb(33, 33, 33);

$color-card: #1B1D21;

$color-background: #02050A;
$color-border-light: rgba(237, 203, 34, 0.15);

$color-button-color: white;
$color-button-bg-1: rgba(255, 255, 255, 0.15);
$color-button-bg: rgba(19,24,35,.34);
$color-button-bg-hover: rgba(255, 255, 255, 0.15);


// FONT
$default-font-size: 1.6rem;
